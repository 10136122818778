<template>
  <div class="parcel-form">
    <v-form ref="form" class="parcel-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">Parcel Information</h3>

      <v-row>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
            @input="setFormByBuilding"
          />

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Property</label>
          <SelectProperty
            flat
            solo
            required
            class="mt-2"
            placeholder="Select Property"
            v-model="form.property_id"
            :buildingId="form.building_id"
            :error-messages="form.$getError('property_id')"
            :error="form.$hasError('property_id')"
            :loading="form.$busy"
            :disabled="form.$busy || form.$busy"
          />

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Recipient</label>
          <SelectUser
            flat
            solo
            required
            pre-select
            class="mt-2"
            placeholder="Select User"
            v-model="form.user_id"
            :propertyId="form.property_id"
            :error-messages="form.$getError('user_id')"
            :error="form.$hasError('user_id')"
            :loading="form.$busy"
            :disabled="form.$busy || form.$busy"
          />

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Type</label>
          <SelectParcelType
            flat
            solo
            required
            class="mt-2"
            v-model="form.parcel_type_id"
            :buildingId="form.building_id"
            :error-messages="form.$getError('parcel_type_id')"
            :error="form.$hasError('parcel_type_id')"
            :loading="form.$busy"
            :disabled="form.$busy || form.$busy"
            text-column="type"
          />

          <label class="text-field-label">Parcel Reference</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Parcel Reference"
            v-model="form.parcel_id"
            :error-messages="form.$getError('parcel_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Parcel Location</label>
          <SelectParcelLocation
            flat
            solo
            required
            class="mt-2"
            v-model="form.parcel_location_id"
            :buildingId="form.building_id"
            :error-messages="form.$getError('parcel_location_id')"
            :error="form.$hasError('parcel_location_id')"
            :loading="form.$busy"
            :disabled="form.$busy || form.$busy"
          />
          <label class="text-field-label">Notifications Sent</label>
          <v-row class="mt-0">
            <v-col cols="4">
              <v-checkbox
                v-model="form.send_sms"
                hide-details="auto"
                label="SMS"
                class="mt-0"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="form.send_email"
                hide-details="auto"
                label="Email"
                class="mt-0"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="form.send_push_noti"
                hide-details="auto"
                label="Alert"
                class="mt-0"
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >Update</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Parcel Update Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SelectBuilding from '@/components/fields/SelectBuilding'
import SelectProperty from '@/components/fields/SelectProperty'
import SelectUser from '@/components/fields/SelectUser'
import SelectParcelType from '@/components/fields/SelectParcelType'
import SelectParcelLocation from '@/components/fields/SelectParcelLocation'
export default {
  components: {
    SelectBuilding,
    SelectProperty,
    SelectUser,
    SelectParcelType,
    SelectParcelLocation,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    parcel: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        property_id: '',
        building_id: '',
        user_id: '',
        parcel_type_id: '',
        parcel_id: '',
        parcel_location_id: '',
        send_sms: false,
        send_email: false,
        send_push_noti: false,
      }),
    }
  },

  computed: {
    ...mapState({
      createdParcel: (state) => state.parcel.parcelDetails,
      permissions: (state) => state.auth.permissions,
    }),

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.PARCEL_UPDATE], this.permissions)
    },
  },

  watch: {
    parcel() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      updateParcel: 'parcel/updateParcel',
    }),

    initForm() {
      if (this.parcel) {
        this.form.property_id = this.parcel.property_id
        this.form.building_id = this.parcel.building_id
        this.form.user_id = this.parcel.user_id
        this.form.parcel_type_id = this.parcel.parcel_type_id
        this.form.parcel_id = this.parcel.parcel_id
        this.form.parcel_location_id = this.parcel.parcel_location_id
        this.form.send_sms = this.parcel.send_sms === 1
        this.form.send_email = this.parcel.send_email === 1
        this.form.send_push_noti = this.parcel.send_push_noti === 1
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.updateParcel(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Parcel details successfully updated!')
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      form.id = this.parcel.id
      form.send_sms = form.send_sms ? 1 : 0
      form.send_email = form.send_email ? 1 : 0
      form.send_push_noti = form.send_push_noti ? 1 : 0

      return form
    },

    setFormByBuilding() {
      this.form.property_id = ''
      this.form.parcel_type_id = ''
      this.form.parcel_location_id = ''
      this.form.user_id = ''
    },
  },
}
</script>
<style lang="scss">
.parcel-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
