var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"btn",style:({
        backgroundColor: '#606060',
        cursor: _vm.disabled ? 'not-allowed' : 'pointer',
        opacity: _vm.disabled ? '60%' : '100%',
      }),attrs:{"dark":"","color":_vm.colorPrimary ? 'primary' : null,"loading":_vm.loading,"height":"40px"}},on),[(_vm.showIcon)?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-chevron-down")]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" ")],1)]}}])},[(!_vm.disabled)?_c('v-list',_vm._l((_vm.subItems),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{style:({
          cursor: item.permission ? 'pointer' : 'not-allowed',
          color: item.permission ? 'black' : 'gray',
        }),on:{"click":function($event){item.permission && _vm.$emit('selected', item.value)}}},[_vm._v(_vm._s(item.label))])],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }